import React from 'react';
import PropTypes from 'prop-types';
import Head from 'components/head';
import Header from 'components/header';
import Footer from 'components/footer';
import styles from './aboutPage.module.scss';
import './aboutPageAnimations.scss';
import SVG from 'react-svg';
import Particles from 'react-particles-js';

const aboutPage = ({ children }) => (
  <div className={styles.root}>
    <Head />
    <Header />
    <div className={styles.homepagehero}>
      <div className={styles.hompageherobg}>
        <Particles
          params={{
            particles: {
              line_linked: {
                enable: false,
                opacity: 0.1,
                shadow: {
                  enable: false,
                  color: '#3CA9D1',
                  blur: 5,
                },
              },
              number: {
                value: 150,
                density: {
                  enable: true,
                  value_area: 800,
                },
              },
              move: {
                enable: true,
                speed: 0.2,
              },
            },
          }}
        />
      </div>
      <div className="test" />
      <SVG className={styles.aliens} src={'/icons/alien_planet.svg'} />
      <SVG className={styles.blackhole} src={'/icons/blackhole.svg'} />
      <SVG className={styles.comet} src={'/icons/comet.svg'} />
      <div className={styles.fadeout} />
      <div className={styles.container}>
        <div className={styles.welcome}>
          <SVG className={styles.planet} src={'/icons/planet.svg'} />
          <SVG className={styles.satellite} src={'/icons/satellite.svg'} />
        </div>
        <SVG className={styles.aliens_two} src={'/icons/aliens_two.svg'} />
        <div className={styles.bottom}>
          <SVG className={styles.asteroids} src={'/icons/asteroid_belt.svg'} />
          <SVG className={styles.starships} src={'/icons/starships.svg'} />
        </div>
      </div>
    </div>
    {children}
    <Footer />
  </div>
);

aboutPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default aboutPage;
