import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import AboutPage from 'components/layout/aboutPage';
import Box from 'components/box/boxColumn';
import Helmet from 'react-helmet';
import styles from './about.module.scss';
import Head from 'components/head';

const About = ({ data }) => {
  return (
    <div>
      <AboutPage>
        <Head pageTitle="About me" />
        <Helmet
          meta={[
            {
              name: 'description',
              content:
                'It\'s me mb. I\'m a UX / UI specialist who also likes to write. As you\'ve probably noticed a lot is happening here. :)',
            },
            {
              name: 'keywords',
              content:
                'ui / ux, ui, ux, design, writing, code, opinions, pop, culture, technology, stuff',
            },
          ]}
        />
        <Box>
          <div className={[styles.row, styles.singlePost].join(' ')}>
            <div
              className={[
                styles.column6,
                styles.centerMargin,
                styles.post,
              ].join(' ')}
            >
              <div
                className={styles.post}
                dangerouslySetInnerHTML={{
                  __html: data.aboutJson.content.childMarkdownRemark.html,
                }}
              />
            </div>
          </div>
        </Box>
      </AboutPage>
    </div>
  );
};

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;

export const query = graphql`
  query AboutQuery {
    aboutJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
